import React, { useEffect, useState, memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Controller, useForm } from 'react-hook-form';
import { DialogContent } from '@mui/material';
import { requiredMessage } from '../../utils/helpers';
import allPaths from '../../Config/paths';
import { BootstrapDialog, BootstrapDialogTitle } from '../Common';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '../Common';
import { errorMessage } from '../../utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { POST } from '../../utils/apis';
import {
  EmailIcon,
  EmailShareButton,
  RedditIcon,
  RedditShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  TwitterShareButton
} from 'react-share';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const ShareModal = memo((props) => {
  const {
    ValidateInputs,
    open,
    handleClose,
    modelConfiguration,
    setModelConfiguration,
    modelId,
    currentConfiguration,
    productPrice,
    configId,
    dataImg,
    StorLocNewSchema,
    allConfigurationInputs
  } = props;
  const [cachedUrl, setCachedUrl] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [hasConfigId, setConfigId] = useState(modelConfiguration.hasOwnProperty('urlConfig_id') ? true : false);

  const handleSaveClick = () => {
    setShowInput(true);
  };
  const [disable, setDisable] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const title = 'checkout this awesome new custom product I designed on Spokbee:';

  const handleCloseForm = () => {
    handleClose();
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    extraNotes: Yup.string()
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      linkType: 'new',
      username: '',
      email: '',
      extraNotes: ''
    }
  });
  const watchFormValues = watch();

  const handleResetForm = () => {
    reset();
    setDisable(false);
  };

  const submitHandler = (formData) => {
    console.log('🚀 ~ submitHandler ~ formData:', formData);
    ValidateInputs();
    if (Object.keys(errors).length === 0) {
      let configurationSelected = currentConfiguration;

      if (props?.modelData?.hasLookupfields) {
        const paramName = props?.modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId;
        const paramVal = JSON.stringify(props?.configurationInputs);

        configurationSelected = `${paramName}=${paramVal}`;
      }

      console.log('file: ShareModal.jsx:115 ~ submitHandler ~ configurationSelected:', props, allConfigurationInputs);
      // return false;
      formData.baseFigure_id = modelId;
      formData.isNewSchema = StorLocNewSchema;
      // formData.configuration = currentConfiguration;
      formData.configuration = configurationSelected;
      formData.configId = configId;
      // formData.dataImg = dataImg;
      formData.productPrice = productPrice;

      const queryObj = {};

      queryObj['baseFigure_id'] = modelId;
      queryObj['configuration'] = currentConfiguration;
      queryObj['configId'] = configId;
      queryObj['dataImg'] = dataImg;
      queryObj['productPrice'] = productPrice;
      queryObj['email'] = formData.email;
      queryObj['extraNotes'] = formData.extraNotes;
      queryObj['linkType'] = formData.linkType;
      queryObj['username'] = formData.username;
      queryObj['isNewSchema'] = formData.isNewSchema;

      let params = new URLSearchParams(location.search);

      axios
        .post(POST.SAVE_CONFIGURATION, queryObj)
        .then((res) => {
          const { data } = res;
          if (data?.success) {
            enqueueSnackbar(data?.message, { variant: 'success' });
          }
          if (data?.savedConfiguration) {
            if (params.has('configId')) {
              params.set('configId', data?.savedConfiguration?.urlConfig_id);
            } else {
              params.append('configId', data?.savedConfiguration?.urlConfig_id);
            }

            navigate(`${location.pathname}?${params.toString()}`);
            setModelConfiguration(data?.savedConfiguration);
            setValue('linkType', 'update');
          }
        })
        .catch((e) => {
          console.log('e', e);
          return enqueueSnackbar(errorMessage(), { variant: 'error' });
        });
    }
  };

  const handleCopyClipBoard = () => {
    if (cachedUrl) {
      navigator.clipboard
        .writeText(cachedUrl)
        .then(() => {
          enqueueSnackbar('URL copied to clipboard', { variant: 'success' });
        })
        .catch((err) => {
          // console.error('Failed to copy: ', err);
          enqueueSnackbar('Failed to copy: ', err, { variant: 'error' });
        });
    }
  };

  // useEffect(() => {
  //   setValue('username', modelConfiguration.username || '');
  //   setValue('email', modelConfiguration.email || '');
  //   setValue('extraNotes', modelConfiguration.extraNotes || '');
  //   setCachedUrl(
  //     `${process.env.REACT_APP_SITEURL}${allPaths.EDITOR}?id=${modelConfiguration.baseFigure_id}&configId=${modelConfiguration.urlConfig_id}`
  //   );
  //   if (modelConfiguration && modelConfiguration?.urlConfig_id) {
  //     setDisable(true);
  //   }
  // }, [modelConfiguration]);

  useEffect(() => {
    if (getValues('linkType') === 'update') {
      setValue('username', modelConfiguration.username || '');
      setValue('email', modelConfiguration.email || '');
      setValue('extraNotes', modelConfiguration.extraNotes || '');
      setCachedUrl(
        `${process.env.REACT_APP_SITEURL}${allPaths.EDITOR}?id=${modelConfiguration.baseFigure_id}&configId=${modelConfiguration.urlConfig_id}`
      );
    } else {
      setValue('username', '');
      setValue('email', '');
      setValue('extraNotes', '');
      setCachedUrl('');
      setDisable(true);
    }
  }, [watch('linkType')]);

  return (
    <BootstrapDialog
      fullWidth={true}
      maxWidth="sm"
      onClose={handleClose}
      open={open}
      aria-labelledby="shareModelLabel"
      aria-describedby="shareModelLabel"
    >
      <BootstrapDialogTitle onClose={handleCloseForm}>Save</BootstrapDialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid container={true} spacing={2} direction="column">
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography gutterBottom variant="h6" className="block-title text-center">
                Save Your Configuration
              </Typography>
              <Typography paragraph={true} lineHeight={2} className="text-center">
                View it anywhere, and share it with others!
              </Typography>
            </Grid>
            <Grid item className="mb-3">
              {modelConfiguration && modelConfiguration?.urlConfig_id && (
                <Controller
                  name="linkType"
                  control={control}
                  defaultValue="new"
                  render={({ field }) => (
                    <RadioGroup {...field} sx={{ display: 'flex', flexDirection: 'row' }} name="radio-buttons-group">
                      <FormControlLabel value="new" control={<Radio />} label="Save a new Design!" />
                      <FormControlLabel value="update" control={<Radio />} label="Replace your Existing Design!" />
                    </RadioGroup>
                  )}
                />
              )}
            </Grid>
            <Grid item className="mb-3">
              <Controller
                name="username"
                control={control}
                rules={{ required: requiredMessage('Your Name') }}
                render={({ field }) => (
                  <MuiInput
                    fullWidth={true}
                    {...field}
                    placeholder="Your Name"
                    error={errors?.username ? true : false}
                    // defaultValue={cachedConfiguration.username || ""}
                  />
                )}
              />
              {errors.username && <ErrorMessage msg={errors.username.message} />}
            </Grid>

            <Grid item className="mb-3">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: requiredMessage('Your Email'),
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: 'Please Enter Valid Email Address!'
                  }
                }}
                // shouldUnregister={true}
                render={({ field }) => (
                  <MuiInput
                    fullWidth={true}
                    {...field}
                    placeholder="Your Email"
                    error={errors?.email ? true : false}
                    // defaultValue={cachedConfiguration.email || ""}
                  />
                )}
              />
              {errors.email && <ErrorMessage msg={errors.email.message} />}
            </Grid>
            <Grid item className="mb-3">
              <Controller
                name="extraNotes"
                control={control}
                // shouldUnregister={true}
                render={({ field }) => (
                  <MuiInput
                    fullWidth={true}
                    {...field}
                    multiline={true}
                    placeholder="Your Notes"
                    // defaultValue={cachedConfiguration.extraNotes || ""}
                    rows={3}
                  />
                )}
              />
              {errors.extraNotes && <ErrorMessage msg={errors.extraNotes.message} />}
            </Grid>
            {modelConfiguration && modelConfiguration?.urlConfig_id && getValues('linkType') === 'update' && (
              <>
                <Grid item className="mb-3">
                  <Typography paragraph={true} sx={{ textAlign: 'center' }} lineHeight={2} className="mb-0" fontWeight={600}>
                    Check your inbox for a link to share your configurations.
                  </Typography>
                </Grid>
                <Grid item className="mb-3" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                  <EmailShareButton url={cachedUrl} subject={'Checkout what I designed on Spokbee!'} body={title}>
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <FacebookShareButton url={cachedUrl}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={cachedUrl} title={title}>
                    <XIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton url={cachedUrl} title={title} separator=":: ">
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <RedditShareButton url={cachedUrl} title={title} windowWidth={660} windowHeight={460}>
                    <RedditIcon size={32} round />
                  </RedditShareButton>
                  <LinkedinShareButton url={cachedUrl}>
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </Grid>
                <Grid item className="mb-3">
                  <MuiInput sx={{ width: '85%' }} readOnly={true} value={cachedUrl} />
                  <Button
                    sx={{ mb: '12px', ml: '7px' }}
                    variant="contained"
                    className="Button"
                    disabled={!disable}
                    onClick={handleCopyClipBoard}
                  >
                    Copy
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs>
              {/* <Button variant="contained" className="Button" type="submit" onClick={() => ValidateInputs()}> */}
              <Button variant="contained" className="Button" type="submit">
                Save
              </Button>
              {/* <Button variant="contained" className="Button" sx={{ marginLeft: '10px' }} type="submit" onClick={handleResetForm}>
                Reset
              </Button> */}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </BootstrapDialog>
  );
});

export default ShareModal;
