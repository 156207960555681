import { LOGIN_ADMIN, REMOVE_ADMIN } from '../types';

const loginAdmin = (admin) => {
  return {
    type: LOGIN_ADMIN,
    admin
  };
};

const removeAdmin = () => {
  console.log('🚀 ~ file: adminAuthActions.jsx:17 ~ removeAdmin ~ removeAdmin:');
  return {
    type: REMOVE_ADMIN
  };
};

export { loginAdmin, removeAdmin };
