import shippoApi from "../Shippo";

/*
  to get all the carrier service from the shippo
*/

export async function listAllCarrierService() {
  try {
    const result = await shippoApi.get('/parcel-templates/');
    // console.log("🚀 ~ listAllCarrierService ~ result:", result);
    return result;
  } catch (error) {
    console.log("🚀 ~ listAllCarrierService ~ error:", error)
    throw new Error(`Failed to listAllCarrierService: ${error?.message}`);
  }   
}

/*
 To Retrieve a carrier parcel template
 @params
 CarrierParcelTemplateToken : string The unique string representation of the carrier parcel template (required)
*/

export async function RetrieveCarrierParcelTemplate(carrierTemplate) {
    try {
       const result = await shippoApi.get(`/parcel-templates/${carrierTemplate}`);
       return result;
       console.log("🚀 ~ RetrieveCarrierParcelTemplate ~ result:", result)
    } catch (error) {
        console.log("🚀 ~ RetrieveCarrierParcelTemplate ~ error:", error)
        throw new Error(`Failed to listAllCarrierService: ${error?.message}`);
    }
}