// assets
import { DashboardOutlined, DollarOutlined } from '@ant-design/icons';
import { allPaths } from '../../utils/constants';

// icons
const icons = {
  DashboardOutlined,
  DollarOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const admin = {
  id: 'main-menu',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: allPaths.ADMIN.DASHBOARD,
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'plans',
      title: 'Subscription Plans',
      type: 'item',
      url: allPaths.ADMIN.SUBSCCRIPTION_PLANS,
      icon: icons.DollarOutlined,
      breadcrumbs: true
    },
    {
      id: 'products',
      title: 'Products',
      type: 'item',
      url: allPaths.ADMIN.PRODUCTS,
      icon: icons.DollarOutlined,
      breadcrumbs: true
    }
  ]
};

export default admin;
