import { Badge, Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { convertTitle } from '../../utils/helpers';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockIcon from '@mui/icons-material/Lock';
import { Link } from 'react-router-dom';
import { allPaths } from '../../utils/constants';

const ProductBox = (props) => {
  const { product } = props;
  const theme = useTheme();
  const tempImgUrl = 'https://res.cloudinary.com/q5rvi3yczj/image/upload/v1658101381/NextRing1_3_2022-Rendering-_1__wqztue.avif';

  return (
    <>
      <Grid item className="prod-card" xs={12} sm={4} md={3} lg={3} xl={3} textAlign="left">
        <Box
          className="image-wrapper"
          sx={{
            borderRadius: '10px',
            border: `1px solid ${props?.isBestSelling ? theme?.palette?.custom?.white : theme?.palette?.custom?.gray}`,
            height: '208px',
            overflow: 'hidden',
            position: 'relative',
            textAlign: 'center'
          }}
        >
          {product?.isExclusive === true && (
            <Badge
              sx={{
                position: 'absolute',
                top: '12px',
                right: '12px',
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: 1.4,
                color: theme.palette.custom.white,
                backgroundColor: theme.palette.secondary.dark50,
                paddingX: '9px',
                borderRadius: '10px',
                border: `1px solid ${theme.palette.custom.white50}`
              }}
            >
              Exclusive
            </Badge>
          )}
          <Link
            to={
              product?.isUpcoming === true
                ? '/'
                : product.id === '63742f7d92623bbb479a2176'
                ? `${allPaths.STORE}?storeId=345&id=${product.id}`
                : `${allPaths.EDITOR}?id=${product.id}`
            }
          >
            <Box
              component="img"
              src={product?.media?.filter((v) => v?.type?.includes('image') && v?.isHome)[0]?.url || tempImgUrl}
              sx={{
                objectFit: 'contain',
                height: '100%',
                cursor: `${product?.isUpcoming === true ? 'auto' : 'pointer'}`
              }}
            />
            {product?.isPrivate == true && (
              <Box
                sx={{
                  position: 'absolute',
                  color: '#FF9315',
                  top: '10px',
                  left: '10px'
                }}
              >
                <Tooltip title="Need log in to gain access">
                  <LockIcon />
                </Tooltip>
              </Box>
            )}
          </Link>
        </Box>
        <Typography
          component={Link}
          to={
            product?.isUpcoming === true
              ? '/'
              : product.id === '63742f7d92623bbb479a2176'
              ? `${allPaths.STORE}?storeId=345&id=${product.id}`
              : `${allPaths.EDITOR}?id=${product.id}`
          }
          sx={{
            marginTop: '15px',
            marginBottom: '6px',
            fontSize: '18px',
            lineHeight: 1.4,
            fontWeight: 400,
            color: props?.isBestSelling ? theme.palette.custom.white : theme.palette.secondary.dark,
            cursor: `${product?.isUpcoming === true ? 'auto' : 'pointer'}`
          }}
        >
          {convertTitle(product.name)}
        </Typography>

        {product?.isUpcoming ? (
          <></>
        ) : (
          <Typography
            component={Link}
            to={
              product.id === '63742f7d92623bbb479a2176'
                ? `${allPaths.STORE}?storeId=345&id=${product.id}`
                : `${allPaths.EDITOR}?id=${product.id}`
            }
            sx={{
              color: theme.palette.primary.dark,
              fontSize: '16px',
              lineHeight: 1.4,
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
              cursor: `${product?.isUpcoming === true ? 'auto' : 'pointer'}`,
              maxWidth: 'max-content'
            }}
          >
            Customize <KeyboardArrowRightIcon />
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default ProductBox;
