import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import CardGiftcard from '@mui/icons-material/CardGiftcard'; // eslint-disable-line  no-unused-vars
import { setActiveMenu } from '../../utils/helpers'; // eslint-disable-line  no-unused-vars
import { drawerRoutes, allPaths } from '../../utils/constants'; // eslint-disable-line  no-unused-vars
import SavingsIcon from '@mui/icons-material/Savings'; // eslint-disable-line  no-unused-vars
import AdUnitsIcon from '@mui/icons-material/AdUnits'; // eslint-disable-line  no-unused-vars
import TokenIcon from '@mui/icons-material/Token'; // eslint-disable-line  no-unused-vars
import QuizIcon from '@mui/icons-material/Quiz'; // eslint-disable-line  no-unused-vars
import NotificationsIcon from '@mui/icons-material/Notifications'; // eslint-disable-line  no-unused-vars

const { SubMenu } = Menu; // eslint-disable-line  no-unused-vars

const SideMenu = (props) => {
  const { location, inlineCollapsed, isAdmin, user } = props; // eslint-disable-line  no-unused-vars
  const [key, setKey] = useState(1); // eslint-disable-line  no-unused-vars
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    // window.addEventListener('resize', setSiderMargin)
    setSiderMargin();
  }, []);

  const setSiderMargin = () => {
    window.innerWidth < 500 ? setMobile(true) : setMobile(false);
  };

  const handleClick = (e) => {
    setKey(parseInt(e?.key));
  };

  return (
    <div className="home-main">
      <div className="flex-row">
        <div style={{ marginTop: mobile ? 56 : 64 }}>
          <Menu
            theme={'black'}
            // style={{ height: `calc(100vh - 65px)` }}
            onClick={handleClick}
            style={{ height: /*  `${window.innerHeight}`  */ `calc(100vh - 65px)`, color: 'black' /* backgroundColor: `${bgColor}` */ }}
            // defaultOpenKeys={[`${setActiveMenu(location?.pathname)}`]}
            // defaultSelectedKeys={[`${setActiveMenu(location?.pathname)}`]}
            mode="inline"
            inlineCollapsed={inlineCollapsed}
          >
            {drawerRoutes?.map((v, i) => {
              return (
                <Menu.Item key={i} icon={v.icon} sx={{ color: 'black' }}>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={v?.route}
                    onClick={() => {
                      return false;
                    }}
                    className="side-list"
                  >
                    {v.title}
                  </Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
