import React, { useCallback, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { SnipcartProvider } from '../Context/SnipcartProvider';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import Banner from './Bannner';
import { CATEGORIES } from '../../utils/apis';
import axios from 'axios';
import { Box, Button, Container, Typography } from '@mui/material';
import { BannerOverLay } from '../Common';
import { store } from '../../Redux/store';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { allPaths, LIBERTY_PEN_GUN } from '../../utils/constants'; 

// custom hooks
import useIframe from '../../Hooks/useIframe';

axios.interceptors.request.use(
  (request) => {
    const { admin } = store.getState()['adminAuthReducer'];
    if (admin && admin?.accessToken && admin?.accessToken != '') {
      request.headers.Authorization = `Bearer ${admin?.accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const MenuLayout = (props) => {
  const { component: Component } = props;
  const location = useLocation();
  const modelId = new URLSearchParams(location?.search).get('id') || '';
  const isIframe = useIframe();
  const [searchParams] = useSearchParams();
  const [previousPath, setPreviousPath] = useState('');
  const [currentPath, setCurrentPath] = useState('');
  const [isHomePage, setIsHomePage] = useState(false);
  // const [isIframe,setIsIframe] = useState(false);
  const { admin } = useSelector((state) => state.adminAuthReducer);

  const includeAllCat = false;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getPrpductCategories();
  }, []);

  // set homepage flag based on changed location
  useEffect(() => {
    if (location.pathname == '/') {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [location]);

  // update current and previous path based on changed location
  useEffect(() => {
    if (previousPath === '' && currentPath === '') {
      setPreviousPath(location.pathname);
      setCurrentPath(location.pathname);
    } else {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getPrpductCategories = useCallback(() => {
    axios
      .get(`${CATEGORIES}?include_all=${includeAllCat}`)
      .then((res) => {
        const { data } = res;
        setCategories(data);
      })
      .catch((e) => {
        console.log('e', e);
      });
  });

  return (
    <SnipcartProvider>
      {searchParams.has('documentId') ? (
        <>
          <Component
            {...props}
            previousPath={previousPath}
            currentPath={currentPath}
            categories={categories}
            includeAllCat={includeAllCat}
          />
        </>
      ) : (
        <>
          {!isHomePage && (
            <>
              {isIframe && modelId === LIBERTY_PEN_GUN ? null : (
                <>
                  {props?.showBanner ? (
                    <Box
                      sx={{
                        backgroundImage: `url(${props?.bannerImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        maxHeight: '600px'
                      }}
                    >
                      <BannerOverLay>
                        <Header {...props} />
                        <Container
                          maxWidth="desktop"
                          sx={{
                            marginTop: {
                              xs: '25px',
                              sm: '20px',
                              md: '80px',
                              lg: '278px'
                            }
                          }}
                        >
                          <Typography variant="pageTitle">{props?.pageTitle}</Typography>
                          <Typography variant="pageSubTitle">{props?.pageSubTitle}</Typography>
                        </Container>
                      </BannerOverLay>
                    </Box>
                  ) : (
                    <Header {...props} isIframe={isIframe} />
                  )}
                </>
              )}
            </>
          )}

          {isHomePage === true && (
            <>
              <Header {...props} />
              <Banner {...props} />
            </>
          )}

          <Box className={`helper-comp`} sx={{ minHeight: '350px', position: 'relative' }}>

            {admin && admin?.userRole == '2' && admin?.onshapeAccessToken && admin && admin?.userRole == 2 && admin?.onshapeAccessToken != '' && (


              <Box sx={{ mt: 2, textAlign: 'center', mb: 2, position: 'fixed', top: '80px', right: 0, zIndex: 99 }}>
                {/* <Button
                  variant="outlined"
                  sx={{
                    color: '#FFFFFF !important',
                    borderWidth: '3px !important',
                    borderColor: '#2C2B2B !important',
                    background: '#2C2B2B !important',
                    maxWidth: '150px',
                    boxShadow: '#FFB63B -1px 0px 0px 4px',
                    opacity: 0.7,
                    '&:hover': {
                      opacity: 1
                    }
                  }}
                  component={Link}
                  to={allPaths.SELLER.DASHBOARD}
                >
                  <ArrowBackIcon /> Return to Seller Experience
                </Button> */}
              </Box>
            )}
            <Component
              {...props}
              previousPath={previousPath}
              currentPath={currentPath}
              categories={categories}
              includeAllCat={includeAllCat}
            />
          </Box>

          {!isIframe && (
            <Box id="footer-wrapper">
              <Footer isHomePage={isHomePage} categories={categories} />
            </Box>
          )}
        </>
      )}
    </SnipcartProvider>
  );
};

export default MenuLayout;
