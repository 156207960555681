import axios from 'axios';

const shippoApi = axios.create({
  baseURL: 'https://cors.redoc.ly/https://api.goshippo.com', 
});

// Request interceptor to add the Shippo API token
shippoApi.interceptors.request.use(
  config => {
    const apiToken = 'shippo_live_7269cd92ebfe7d477a5a53ed3fa52ebfe1d2b65f'; 
    config.headers.Authorization = `ShippoToken ${apiToken}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default shippoApi;
