import React, { memo } from 'react';

import CategoriesList from '../../Components/Home/Categories';
import FeaturedProducts from '../../Components/Home/FeaturedProducts';
import BestSelling from '../../Components/Home/BestSelling';
import WhyChoose from '../../Components/Home/WhyChoose';
import UpcomingLaunches from '../../Components/Home/UpcomingLaunches';
import OurPartners from '../../Components/Home/OurPartners';
import { Button } from '@mui/material';

import { listAllCarrierService } from '../../utils/ShippoService/CarrierParcelTemplatesServices/CarrierParcelTemplatesServices';

import { store } from '../../Redux/store';
import axios from 'axios';

const Home = memo((props) => {
  const { categories } = props;

  const handleServices = async () => {
    const test = await listAllCarrierService();
  };

  return (
    <>
      <CategoriesList categories={categories} />
      <FeaturedProducts />
      {/* <BestSelling /> */}
      <WhyChoose />
      {/* <UpcomingLaunches /> */}
      <OurPartners />
    </>
  );
});

export default Home;
