import { useEffect, useState } from 'react';
import { matchPath, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Backdrop, Box, IconButton, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from '../../menu-items';
import Breadcrumbs from '../../Components/@extended/Breadcrumbs';

// types
import { enqueueSnackbar } from 'notistack';
import { openDrawer } from '../../../Redux/actions/menuActions';
import '../../assets/css/admin.css';
import axios from 'axios';
import { store } from '../../../Redux/store';
import { removeAdmin } from '../../../Redux/actions/adminAuthActions';
import { allPaths } from '../../../utils/constants';
import moment from 'moment';
import LockIcon from '@mui/icons-material/Lock';

const ADMIN_ROUTES = [
  allPaths.ADMIN.DASHBOARD,
  allPaths.ADMIN.PRODUCTS,
  allPaths.ADMIN.PROFILE,
  allPaths.ADMIN.CHANGE_PASSWORD,
  allPaths.ADMIN.SUBSCCRIPTION_PLANS
];

const ROUTES_MAP = {
  onshape: [
    allPaths.SELLER.DASHBOARD,
    allPaths.SELLER.PRODUCTS,
    allPaths.SELLER.MY_PROFILE,
    allPaths.SELLER.EDIT_PRODUCTS,
    allPaths.SELLER.ADD_PRODUCTS,
    allPaths.SELLER.UPDATE_PASSWORD,
    allPaths.SELLER.TUTORIAL_AND_RESOURCES
  ],
  shippoShipping: [
    allPaths.SELLER.DASHBOARD,
    allPaths.SELLER.CREATE_LABEL,
    allPaths.SELLER.ORDERS,
    allPaths.SELLER.UPDATE_PASSWORD,
    allPaths.SELLER.MY_PROFILE
  ],
  privateStorefront: [
    allPaths.SELLER.DASHBOARD,
    allPaths.SELLER.USERS,
    allPaths.SELLER.ADDUSER,
    allPaths.SELLER.EDITUSER,
    allPaths.SELLER.UPDATE_PASSWORD,
    allPaths.SELLER.MY_PROFILE
  ]
};

// ==============================|| MAIN LAYOUT ||============================== //

const configureAxiosInterceptors = (dispatch, location, navigate, admin) => {
  axios.interceptors.request.use(
    (request) => {
      if (admin?.accessToken) {
        request.headers.Authorization = `Bearer ${admin.accessToken}`;
      }
      return request;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.data.message == "TokenError") {
        enqueueSnackbar('Session Expired!', { variant: 'warning' });
        if (location.pathname.includes(allPaths.SELLERBASE)) {
          navigate(allPaths.SELLER.LOGIN);
        } else {
          navigate(allPaths.ADMIN.LOGIN);
        }
        dispatch(removeAdmin());
      }
      return Promise.reject(error);
    }
  );
};

const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.adminAuthReducer);
  const navigate = useNavigate();
  const { drawerOpen } = useSelector((state) => state.menuReducer);
  const [remainingDays, setRemainingDays] = useState(0);

  useEffect(() => {
    if (admin && admin?.expiredAfter) {
      const exp = admin?.expiredAfter.split(' ');
      const remainingDays = moment(admin?.subscribedAt).add(exp[0], exp[1]).diff(moment(), 'days', true).toFixed(0);
      setRemainingDays(remainingDays);
    }
  }, [admin]);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    if (!admin) {
      console.log('location.pathname.includes(allPaths.SELLERBASE) ==> ', location.pathname);
      if (location.pathname.includes(allPaths.SELLERBASE)) {
        navigate(allPaths.SELLER.LOGIN);
      } else if (location.pathname.includes(allPaths.ADMINBASE)) {
        navigate(allPaths.ADMIN.LOGIN);
      }
    } else if (admin && admin?.userRole == 3) {
      enqueueSnackbar('Page Not Found', { variant: 'warning' });

      navigate(allPaths.HOME);
    }
  }, [admin, location, navigate]);

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  useEffect(() => {
    configureAxiosInterceptors(dispatch, location, navigate, admin);
  });

  const isPathFound = (allowedRoutes) => {
    const matchedRoutes = allowedRoutes.filter((route) => {
      const match = useMatch(route);
      return !!match; // Return true if there's a match, false otherwise
    });

    return matchedRoutes.length > 0;
  };

  const renderOutlet = (path) => {


    const permissionGroups = admin?.permissionGroup || [];
    let allowedRoutes = [];

    if (admin?.userRole === 1) {
      allowedRoutes = ADMIN_ROUTES;
    } else if (admin?.userRole === 2) {
      allowedRoutes = permissionGroups.flatMap((group) => ROUTES_MAP[group] || []);
    }

    if (!isPathFound(allowedRoutes)) {
      if (admin || admin?.userRole == 3) {
        enqueueSnackbar('Page Not Found', { variant: 'warning' });
        navigate(-1);
      }
      return false;
    }
    return <Outlet context={{ remainingDays }} />;
  };

  return (
    <>
      {admin && admin?.userRole != 3 && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          {admin?.expireAfter && remainingDays <= 0 && (
            <Backdrop sx={{ zIndex: 9 }} open={true}>
              <IconButton size="30px" sx={{ color: theme.palette.primary.dark }} disableRipple>
                <LockIcon sx={{ color: theme.palette.primary.light, fontSize: '50px' }} />
              </IconButton>
            </Backdrop>
          )}
          <Header open={open} handleDrawerToggle={handleDrawerToggle} remainingDays={remainingDays} />
          <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
            <Toolbar />
            <Breadcrumbs navigation={navigation(admin?.userRole, admin?.permissionGroup)} title />
            {renderOutlet(location.pathname)}
            {/* <Outlet context={{ remainingDays }} /> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default MainLayout;
