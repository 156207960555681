import React from 'react';
import { lazy } from 'react';
import lazyRetry from '../utils/lazyRetry';

// project import
import Loadable from '../Admin/Components/Loadable';
import { Outlet, useNavigate, useRoutes } from 'react-router-dom';
import { Login, Signup, Home, Editor, About, Contact, ForgotPassword } from '../Screens';
import { MenuLayout } from '../Components';
import allPaths from './paths';
import { Result, Button } from 'antd';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../Config/theme';
import { useSelector } from 'react-redux';
// import { Settings } from '../Screens/Admin';
import MainLayout from '../Admin/layout/MainLayout';
// import ShippoSellerDashboardLayout from '../Admin/layout/ShippoSellerMainLayout/ShippoSellerDashboardLayout';
import MinimalLayout from '../Admin/layout/MinimalLayout/';
import ThemeCustomization from '../Admin/themes';
import ScrollTop from '../Admin/Components/ScrollTop';
import { GetTrackingStatus } from '../utils/ShippoService/TrackingWebhookServices/TrackingWebhookServices';
import TutorialandResources from '../Screens/Seller/Register/TutorialandResources';

const ResetPassword = Loadable(lazy(() => lazyRetry(() => import('../Screens/Login/ResetPassword'), 'ResetPassword')));
const SetPassword = Loadable(lazy(() => lazyRetry(() => import('../Screens/Login/SetPassword'), 'SetPassword')));
const MyProfile = Loadable(lazy(() => lazyRetry(() => import('../Screens/Login/MyProfile'), 'MyProfile')));
const Faqs = Loadable(lazy(() => lazyRetry(() => import('../Screens/Faqs/Faqs'), 'Faqs')));
const Products = Loadable(lazy(() => lazyRetry(() => import('../Screens/Products/Products'), 'Products')));
const SellOnSpokbee = Loadable(lazy(() => lazyRetry(() => import('../Screens/SellOnSpokbee/SellOnSpokbee'), 'SellOnSpokbee')));
const AuthLogin = Loadable(lazy(() => lazyRetry(() => import('../Admin/Screens/authentication/Login'), 'AuthLogin')));
const OnshapeCallback = Loadable(lazy(() => lazyRetry(() => import('../Admin/Screens/authentication/OnshapeCallback'), 'OnshapeCallback')));
const OnshapeGrantAccess = Loadable(lazy(() => lazyRetry(() => import('../Screens/OnshapeGrantAccess'), 'OnshapeGrantAccess')));
const AdminForgotPassword = Loadable(
  lazy(() => lazyRetry(() => import('../Admin/Screens/authentication/ForgotPassword'), 'AdminForgotPassword'))
);
const AdminResetPassword = Loadable(
  lazy(() => lazyRetry(() => import('../Admin/Screens/authentication/ResetPassword'), 'AdminResetPassword'))
);
const Pricing = Loadable(lazy(() => lazyRetry(() => import('../Screens/Pricing'), 'Pricing')));
const SellerRegister = Loadable(lazy(() => lazyRetry(() => import('../Screens/Seller/Register'), 'SellerRegister')));
const SellerLogin = Loadable(lazy(() => lazyRetry(() => import('../Screens/Seller/Login'), 'SellerLogin')));
const TermsConditions = Loadable(lazy(() => lazyRetry(() => import('../Screens/TermsConditions'), 'TermsConditions')));
const PrivacyPolicy = Loadable(lazy(() => lazyRetry(() => import('../Screens/PrivacyPolicy'), 'PrivacyPolicy')));
const DashboardDefault = Loadable(lazy(() => lazyRetry(() => import('../Admin/Screens/dashboard'), 'DashboardDefault')));
const SubscriptionPlans = Loadable(lazy(() => lazyRetry(() => import('../Admin/Screens/subscription'), 'SubscriptionPlans')));
const AdminProfile = Loadable(lazy(() => lazyRetry(() => import('../Admin/Screens/profile'), 'AdminProfile')));
const UpdatePassword = Loadable(lazy(() => lazyRetry(() => import('../Admin/Screens/updatePassword'), 'UpdatePassword')));
const SellerDashboard = Loadable(lazy(() => lazyRetry(() => import('../Screens/Seller/Dashboard'), 'SellerDashboard')));
const SellerProducts = Loadable(lazy(() => lazyRetry(() => import('../Screens/Seller/Products'), 'SellerProducts')));
const SellerProfile = Loadable(lazy(() => lazyRetry(() => import('../Screens/Seller/Profile'), 'SellerProfile')));
const AddEditProduct = Loadable(lazy(() => lazyRetry(() => import('../Screens/Seller/Products/AddEditProduct'), 'AddEditProduct')));
const AdminProducts = Loadable(lazy(() => lazyRetry(() => import('../Admin/Screens/products'), 'AdminProducts')));
const PreviewProduct = Loadable(lazy(() => lazyRetry(() => import('../Screens/Seller/Products/Preview'), 'PreviewProduct')));
const ShippoSellerCreateNewLabel = Loadable(
  lazy(() => lazyRetry(() => import('../Screens/Shippo/ShippoSellerCreateNewLabel'), 'ShippoSellerCreateNewLabel'))
);
const ShippoOrders = Loadable(lazy(() => lazyRetry(() => import('../Screens/Shippo/ShippoOrders'), 'ShippoOrders')));
const StoreFrontUsers = Loadable(
  lazy(() => lazyRetry(() => import('../Screens/Seller/PrivateStorefront/StoreFrontUsers'), 'StoreFrontUsers'))
);
const StoreFrontAddUsers = Loadable(
  lazy(() => lazyRetry(() => import('../Screens/Seller/PrivateStorefront/StoreFrontUsers/UserForm'), 'StoreFrontAddUsers'))
);

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" className="form-button" onClick={() => navigate('/')}>
          Back Home
        </Button>
      }
    />
  );
};

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon sx={{ fontSize: '20px', padding: '3px', background: '#FFFFFF', borderRadius: '50%' }} />
    </IconButton>
  );
};

const Protected = () => {
  const user = useSelector((state) => state.adminAuthReducer?.admin);

  console.log('file: routes.jsx:98 ~ Protected ~ user:', user);

  const navigate = useNavigate();
  if (user) {
    return <Outlet />;
  }
  return navigate(allPaths.LOGIN);
};

const menuPaths = [
  {
    path: allPaths.HOME,
    element: <MenuLayout component={Home} />
  },
  {
    path: allPaths.LOGIN,
    element: <MenuLayout component={Login} />
  },
  {
    path: allPaths.SIGNUP,
    element: <MenuLayout component={Signup} />
  },
  {
    path: allPaths.FORGOT_PASSWORD,
    element: <MenuLayout component={ForgotPassword} />
  },
  {
    path: allPaths.RESET_PASSWORD,
    element: <MenuLayout component={ResetPassword} />
  },
  {
    path: allPaths.ABOUT,
    element: (
      <MenuLayout
        component={About}
        showBanner={true}
        bannerImage={'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/9054f82d-7286-46df-5475-eda8ba0bc900/public'}
        pageTitle="About Us"
        pageSubTitle="Buy and Sell Customizable, 3D Printed Products"
      />
    )
  },
  {
    path: allPaths.FAQS,
    element: (
      <MenuLayout
        component={Faqs}
        showBanner={true}
        bannerImage={'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/862c6f15-ca16-4401-e5a3-b481b2ac8900/public'}
        pageTitle="FAQs"
        pageSubTitle="It is a long established fact that a reader will be."
      />
    )
  },
  {
    path: allPaths.CONTACT,
    element: (
      <MenuLayout
        component={Contact}
        showBanner={true}
        bannerImage={'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/99fd8b5e-9267-40fd-050c-3de753b8d900/public'}
        pageTitle="Contact Us"
        pageSubTitle="We're here to help!"
      />
    )
  },
  {
    path: allPaths.SELL_ON_SPOKBEE,
    element: (
      <MenuLayout
        component={SellOnSpokbee}
        showBanner={true}
        bannerImage={'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/cf27ea32-c578-440a-7ab6-d7ca75086800/public'}
        pageTitle="Sell On Spokbee"
        pageSubTitle="Creating a Parametrically Configurable Part in Onshape"
      />
    )
  },
  {
    path: allPaths.PRICING,
    element: (
      <MenuLayout
        component={Pricing}
        showBanner={true}
        bannerImage={'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/cf27ea32-c578-440a-7ab6-d7ca75086800/public'}
        pageTitle="Pricing"
        pageSubTitle="Affordability meets exceptional quality"
      />
    )
  },
  {
    path: allPaths.TERMS_CONDITIONS,
    element: (
      <MenuLayout
        component={TermsConditions}
        showBanner={true}
        bannerImage={'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/cf27ea32-c578-440a-7ab6-d7ca75086800/public'}
        pageTitle="Terms & Conditions"
        pageSubTitle="Please Read Carefully Before Using Our Service"
      />
    )
  },
  {
    path: allPaths.PRIVACY_POLICY,
    element: (
      <MenuLayout
        component={PrivacyPolicy}
        showBanner={true}
        bannerImage={'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/cf27ea32-c578-440a-7ab6-d7ca75086800/public'}
        pageTitle="Privacy Policy"
        pageSubTitle="Spokbee Privacy Policy"
      />
    )
  },
  {
    path: allPaths.EDITOR,
    element: <MenuLayout component={Editor} />
  },
  {
    path: allPaths.STORE,
    element: <MenuLayout component={Editor} />
  },
  {
    path: allPaths.PRODUCTS,
    element: <MenuLayout component={Products} />
  },
  {
    path: `${allPaths.PRODUCTS}/:filterType`,
    element: <MenuLayout component={Products} />
  },
  {
    path: `${allPaths.PRODUCTS}/:filterType/:categoryId`,
    element: <MenuLayout component={Products} />
  },
  // May not used
  // {
  //     path: allPaths.ADMIN,
  //     element: <AdminLayout component={FormC} />,
  // },
  // {
  //   path: allPaths.COMPANY_LOGIN,
  //   element: <CompanyLayout component={CompanyLogin} />
  // },
  // {
  //   path: allPaths.COMPANY_REGISTER,
  //   element: <CompanyLayout component={CompnaySignup} />
  // },
  // {
  //   path: allPaths.COMPANY,
  //   element: <CompanyLayout component={Company} />
  // },
  // May not used over
  {
    path: '/:page404',
    element: <Page404 />
  },
  {
    path: allPaths.SELLER.REGISTER,
    element: <MenuLayout component={SellerRegister} />
  },
  {
    path: allPaths.SELLER.LOGIN,
    element: <MenuLayout component={SellerLogin} />
  },
  {
    path: allPaths.SELLER.FORGOT_PASSWORD,
    element: <MenuLayout component={ForgotPassword} />
  },
  {
    path: allPaths.SELLER.RESET_PASSWORD,
    element: <MenuLayout component={ResetPassword} />
  },
  {
    path: allPaths.SELLER.NEW_PASSWORD,
    element: <MenuLayout component={ResetPassword} />
  },
  {
    path: allPaths.INVITE,
    element: <MenuLayout component={SetPassword} />
  },
  {
    path: allPaths.ADMIN.PREVIEW_PRODUCTS,
    element: <MenuLayout component={PreviewProduct} />
  },
  {
    path: allPaths.SELLER.PREVIEW_PRODUCTS,
    element: <MenuLayout component={PreviewProduct} />
  },
  {
    path: allPaths.ONSHAPE_GRANT_ACCESS,
    element: <OnshapeGrantAccess />
  },
  {
    path: allPaths.SELLER.TUTORIAL_AND_RESOURCES,
    element: <MenuLayout component={TutorialandResources} />
  },
  {
    path: '/',
    element: <Protected />,
    children: [
      {
        path: allPaths.MY_PROFILE,
        element: <MenuLayout component={MyProfile} />
      }
    ]
  }
];

const AdminRoutes = [
  {
    path: '/',
    element: <MinimalLayout />,
    children: [
      {
        path: allPaths.ADMIN.LOGIN,
        element: <AuthLogin />
      },
      {
        path: allPaths.ADMIN.FORGOT_PASSWORD,
        element: <AdminForgotPassword />
      },
      {
        path: allPaths.ADMIN.RESET_PASSWORD,
        element: <AdminResetPassword />
      },
      {
        path: allPaths.ONSHAPE_CALLBACK,
        element: <OnshapeCallback />
      }
    ]
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: allPaths.ADMIN.DASHBOARD,
        element: <DashboardDefault />
      },
      {
        path: allPaths.ADMIN.PROFILE,
        element: <AdminProfile />
      },
      {
        path: allPaths.ADMIN.CHANGE_PASSWORD,
        element: <UpdatePassword />
      },
      {
        path: allPaths.ADMIN.SUBSCCRIPTION_PLANS,
        element: <SubscriptionPlans />
      },
      {
        path: allPaths.ADMIN.PRODUCTS,
        element: <AdminProducts />
      },
      {
        path: allPaths.SELLER.MY_PROFILE,
        element: <SellerProfile />
      },
      {
        path: allPaths.SELLER.UPDATE_PASSWORD,
        element: <UpdatePassword />
      },
      {
        path: allPaths.SELLER.DASHBOARD,
        element: <SellerDashboard />
      },
      {
        path: allPaths.SELLER.PRODUCTS,
        element: <SellerProducts />
      },
      {
        path: allPaths.SELLER.ADD_PRODUCTS,
        element: <AddEditProduct />
      },
      {
        path: allPaths.SELLER.EDIT_PRODUCTS,
        element: <AddEditProduct />
      },
      {
        path: allPaths.SELLER.CREATE_LABEL,
        element: <ShippoSellerCreateNewLabel />
      },
      {
        path: allPaths.SELLER.ORDERS,
        element: <ShippoOrders />
      },
      {
        path: allPaths.ADMIN.ADD_PRODUCTS,
        element: <AddEditProduct />
      },
      {
        path: allPaths.ADMIN.EDIT_PRODUCTS,
        element: <AddEditProduct />
      },
      {
        path: allPaths.SELLER.USERS,
        element: <StoreFrontUsers />
      },
      {
        path: allPaths.SELLER.ADDUSER,
        element: <StoreFrontAddUsers />
      },
      {
        path: allPaths.SELLER.EDITUSER,
        element: <StoreFrontAddUsers />
      }
    ]
  }
];

const AllRoutes = () => {
  return (
    <div>
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        preventDuplicate={true}
        autoHideDuration={2000}
        hideIconVariant={true}
        action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      >
        <ThemeProvider theme={theme}>{useRoutes(menuPaths)}</ThemeProvider>
        <ThemeCustomization>
          <ScrollTop>{useRoutes(AdminRoutes)}</ScrollTop>
        </ThemeCustomization>
      </SnackbarProvider>
    </div>
  );
};

export default AllRoutes;
