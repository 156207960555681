import { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Fade
} from '@mui/material';

// project import
import MainCard from '../../../../Components/MainCard';
import Transitions from '../../../../Components/@extended/Transitions';

// assets
import { BellOutlined, CheckCircleOutlined } from '@ant-design/icons';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { NOTIFICAIONS } from '../../../../../utils/apis';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { grey } from '@mui/material/colors';

// for redux
import { useDispatch, useSelector } from 'react-redux';
import { notificaitonClicked } from '../../../../../Redux/actions/notificationFilterAction';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const anchorElOpen = Boolean(anchorEl);

  const [totalCount, setTotalCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [clickedItem, setClickedItem] = useState({});

  const dispatch = useDispatch();

  const fetchNotifications = async () => {
    const filter = {
      status: 'sent'
    };

    const payload = `?${
      filter
        ? Object.keys(filter)
            .filter((key) => {
              return filter[key] !== '';
            })
            .map((key) => {
              return `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`;
            })
            .join('&')
        : ''
    }`;
    axios
      .get(`${NOTIFICAIONS.GET_ALL}${payload}`)
      .then((res) => {
        const { data } = res;
        // console.log('🚀 ~ file: Notification.js:84 ~ .then ~ data:', data);

        setNotifications(data.notifications);
        setTotalCount(data.totalCount);
      })
      .catch((e) => {
        console.log("abaaa dabba jabba", e);
        enqueueSnackbar('Oops No Data Found!', { variant: 'warning' });
      });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const markAsNotification = async (e, notificaiton) => {
    // getting details of the selected item of the notificaiton from clickedItem state
    e.stopPropagation();
    // console.log("working now!");
    // return
    setAnchorEl(null);
    const notificaitonId = [clickedItem.id];
    const UpdatedNotificaiton = [];
    notifications.map((notificaiton) => {
      if (notificaiton.id == notificaitonId) {
        let newNotification = {
          ...notificaiton,
          notificationStatus: 'read'
        };
        UpdatedNotificaiton.push(newNotification);
      } else {
        UpdatedNotificaiton.push({ ...notificaiton });
      }
    });
    console.log('UpdatedNotificaiton', UpdatedNotificaiton);
    setNotifications(UpdatedNotificaiton);
    await axios.post(NOTIFICAIONS.READ, { notificationIds: notificaitonId }).then((res) => {
      console.log('res', res);
      fetchNotifications();
    });
  };

  const markAsUnreadNotification = async () => {
    setAnchorEl(null);
    const notificaitonId = [clickedItem.id];
    const UpdatedNotificaiton = [];
    notifications.map((notificaiton) => {
      if (notificaiton.id == notificaitonId) {
        let newNotification = {
          ...notificaiton,
          notificationStatus: 'sent'
        };
        UpdatedNotificaiton.push(newNotification);
      } else {
        UpdatedNotificaiton.push({ ...notificaiton });
      }
    });
    console.log('UpdatedNotificaiton', UpdatedNotificaiton);
    setNotifications(UpdatedNotificaiton);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef?.current && anchorRef?.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
    setAnchorEl(null);
  };

  const handleMarkAllRead = async (event) => {
    const notificationIds = notifications.map((noti) => {
      return noti._id;
    });
    await axios.post(NOTIFICAIONS.READ, { notificationIds: notificationIds }).then((res) => {
      fetchNotifications();
    });
    handleClose(event);
  };

  const handleMenuList = async (e, notification) => {
    e.preventDefault();
    e.stopPropagation();
    setClickedItem(notification);
    setAnchorEl(e.currentTarget);
  };

  const listItemHandler = (e, notificaiton) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(notificaitonClicked(notificaiton || {}));
    handleClose();
  };

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={totalCount} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <Tooltip title="Mark all as read">
                      <Button variant="contained" size="small" onClick={(event) => handleMarkAllRead(event)}>
                        Mark all as read
                      </Button>
                    </Tooltip>
                  }
                  sx={{ textAlign: 'left' }}
                >
                  {notifications.length > 0 && (
                    <List
                      component="nav"
                      sx={{
                        maxHeight: '300px',
                        overflow: 'auto',
                        p: 0,
                        '& .MuiListItemButton-root': {
                          py: 0.5,
                          '& .MuiAvatar-root': avatarSX,
                          '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                        }
                      }}
                    >
                      {notifications.map((notification, index) => {
                        // const [remainingDays, setRemainingDays] = useState(0);
                        const sentAt = notification.sentAt;
                        const timeAgodays = Math.abs(moment(notification.sentAt).diff(moment(), 'days'));
                        const timeAgoHours = Math.abs(moment(notification.sentAt).diff(moment(), 'hours'));
                        const timeAgoMinutes = Math.abs(moment(notification.sentAt).diff(moment(), 'minutes'));
                        let timeAgo = `${moment(sentAt).format('DD MMMM')}`;
                        if (timeAgodays < 1) {
                          timeAgo = `${timeAgoHours} ${timeAgoHours == 1 ? 'hour' : 'hours'} ago`;
                        }

                        if (timeAgoHours < 1) {
                          timeAgo = `${timeAgoMinutes} ${timeAgoMinutes == 1 ? 'minute' : 'minutes'} ago`;
                        }
                        return (
                          <>
                            <ListItemButton
                              key={`index-${index}`}
                              style={{ backgroundColor: notification?.notificationStatus == 'sent' ? grey[200] : 'white' }}
                              onClick={(e) => listItemHandler(e, notification)}
                            >
                              <ListItem
                                style={{ padding: '0' }}
                                secondaryAction={
                                  <>
                                    <Tooltip title="Mark as read" key={`index-list-${index}`}>
                                      <IconButton
                                        onClick={(e) => handleMenuList(e, notification)}
                                        edge="end"
                                        aria-controls={anchorElOpen ? 'fade-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={anchorElOpen ? 'true' : undefined}
                                        aria-label="delete"
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    </Tooltip>

                                    <Menu
                                      id="fade-menu"
                                      MenuListProps={{
                                        'aria-labelledby': 'fade-button'
                                      }}
                                      anchorEl={anchorEl}
                                      open={anchorElOpen}
                                      onClose={handleClose}
                                      key={`index-menu-${index}`}
                                      PaperProps={{
                                        style: {
                                          boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2)'
                                        }
                                      }}
                                    >
                                      {
                                        // notification?.notificationStatus == 'sent' && (
                                        <MenuItem
                                          key={notification.id}
                                          onClick={(e) => {
                                            markAsNotification(e, notification);
                                          }}
                                        >
                                          Mark as read
                                        </MenuItem>
                                        // )
                                      }
                                      {/* {
                                        notification?.notificationStatus == 'read' && (
                                          <MenuItem
                                          key={notification.id}
                                          onClick={() => {
                                            markAsUnreadNotification(notification);
                                          }}
                                          >
                                            Mark as unread
                                          </MenuItem>
                                        )
                                      } */}
                                    </Menu>
                                  </>
                                }
                              >
                                <Box>
                                  <Typography variant="h6">{parse(notification.notificationText)}</Typography>
                                  <Typography variant="caption">{timeAgo} </Typography>
                                  <Typography variant="caption">{moment(sentAt).format('hh:mm A')}</Typography>
                                </Box>
                              </ListItem>
                            </ListItemButton>
                            {/* </List> */}
                            {notifications.length < index + 1 && <Divider />}
                          </>
                        );
                      })}
                    </List>
                  )}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
